import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

const IndexPage = ({data}) => {
    let siteType = process.env.GATSBY_API_URL;
    let homePageData = data.allStrapiHomePages.edges[0].node;
    let blogData;
    if (siteType === "prod") {
        blogData = data.allStrapiBlogs.edges.filter(edges => edges.node.status === "published");
        blogData.sort((a, b) => new Date(b.node.publishDate) - new Date(a.node.publishDate));
    } else if (siteType === "dev") {
        blogData = data.allStrapiBlogs.edges.filter(edges => edges.node.status === "published" || edges.node.status === "staging");
        blogData.sort((a, b) => new Date(b.node.publishDate) - new Date(a.node.publishDate));
    }

    function formatDate(date) {
        var monthNames = [
            "Januar", "Februar", "März",
            "April", "Mai", "Juni", "Juli",
            "August", "September", "Oktober",
            "November", "Dezember"
        ];

        let updateDate = date.split("-");
        let day = updateDate[2];
        let monthIndex = updateDate[1];
        let year = updateDate[0];

        return day + ". " + monthNames[monthIndex - 1] + " " + year;
    }

    let referenc = [];
    let refdat;
    homePageData.references.forEach(references => {
        data.allStrapiReferences.edges.forEach(edges => {
            if (edges.node.url === references.url) {
                refdat = edges.node;
            }
        });
        referenc.push(refdat)
    });
    let offersData = data.allStrapiOfferPages.edges;
    return (
        <Layout page="home">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Die Schweizer Voice- und Chatbot Experten | Paixon</title>
                <meta name="description"
                      content="Mit Paixon haben Sie den richtigen Partner für individuelle Voice- und Chatbot-Entwicklungen an Ihrer Seite. Entdecken Sie unser Angebot."/>
            </Helmet>
            <Header className="index" slogan={homePageData.slogan}
                    blogphoto={{backgroundImage: `url(${homePageData.photo.publicURL})`}}
                    offersData={offersData}/>
            <main className="container intro pt">
                <div className="offerOptions">
                    <ReactMarkdown className="offerOptionsIntroduction" children={homePageData.cards_section}/>
                    <section>
                        <div className="row offersCardSection">
                            {offersData.filter(edge => edge.node['show_as_card'] === 'yes').sort((a, b) => a.node.order - b.node.order).map(edge => {
                                const offers = edge.node;
                                const description = offers.description.split('*').filter(text => text.length > 0);
                                return (
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                        <div className="offercolumn homeOffer">
                                            <div className="offersection-picture"
                                                 style={{backgroundImage: `url(${offers.card_image.publicURL})`}}>
                                            </div>
                                            <div className="offersection-info">
                                                <h3 className="offersection-info-title">{offers.title}</h3>
                                                <ul className="offersection-description-list">
                                                    {description.map(text => {
                                                        return (
                                                            <li className="offersection-description-item">
                                                                {text.trim()}</li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                            <a className="offercolumn-link-button"
                                               href={`${offers.url}`}>
                                                <div className='offercolumn-link-text'>Mehr erfahren</div>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </section>
                </div>

                <div className="section-white workshop-section">
                    <ReactMarkdown children={homePageData.chatbot_workshop}/>
                    <div className="intro-workshop-button action-button-element">
                        <a className="action-button"
                           href="/angebot/chatbot-workshop">
                            <h3 className="action-button-text">Mehr erfahren</h3>
                        </a>
                    </div>
                </div>

                <div className="section-white team-section">
                    <ReactMarkdown children={homePageData.team_section}/>
                    <div className="intro-team-button action-button-element">
                        <a className="action-button"
                           href="/team">
                            <h3 className="action-button-text">Team kennenlernen</h3>
                        </a>
                    </div>
                </div>

                <div className="introRefrences">
                    <ReactMarkdown children={homePageData.refrences_section}/>
                    <div className="row logos our-projects">
                        {referenc.slice(0, 5).map(edge => {
                            const reference = edge;
                            return (
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <a
                                        className={`reference`}
                                        style={{backgroundImage: `url(${reference.companyImage.publicURL})`}}
                                        href={`/referenzen/${reference.url}`}
                                        title={reference.title}
                                    >
                                        {" "}
                                        {(reference.filmes.length > 0) && (
                                            <div className='reference-logos-movie'>
                                                <div className="reference-logos-movie-icon"></div>
                                            </div>
                                        )}
                                    </a>
                                </div>
                            )
                        })}
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <a
                                href={`/referenzen`}
                                className="reference referenceElement"
                            >
                                <h3>Weitere Referenzen</h3>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="section-white content">
                    <ReactMarkdown children={homePageData.content}/>
                </div>

                <div className="callToAction-section section-white">
                    <ReactMarkdown children={homePageData.callToAction_section}/>
                    <div className="callToAction-button action-button-element">
                        <a className="action-button"
                           href="mailto:info@paixon.ch">
                            <h3 className="action-button-text">{homePageData.callToAction_button}</h3>
                        </a>
                    </div>

                </div>

                <div className="introBlog">
                    <ReactMarkdown children={homePageData.blog_section}/>
                    <section>
                        <div className="blogFlexcontainer">
                            {blogData.slice(0, 2).map(edge => {
                                const blogpost = edge.node;
                                let updateDate = formatDate(blogpost.publishDate);
                                return (
                                    <div className="element nopadding">
                                        <div className="blogcolumn-element">
                                            <div className="blogcolumn">
                                                <a href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                    <div className="blogline"
                                                         style={{
                                                             borderBottom: `10px solid ${blogpost.category.color}`,
                                                             backgroundImage: `url(${blogpost.blog_photo.publicURL})`
                                                         }}>
                                                        <div className="category-icon">
                                                            <div className="triangle-up"
                                                                 style={{borderBottom: `45px solid ${blogpost.category.color}`}}>
                                                            </div>
                                                            {data.allStrapiBlogCategories.edges.filter(edges => edges.node.name === blogpost.category.name)
                                                                .map(edge => {
                                                                    return (
                                                                        <img
                                                                            src={edge.node.blog_category_icon.publicURL}
                                                                            alt={`${blogpost.category.name} icon`}/>

                                                                    )
                                                                })}
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="bloginfo">
                                                <p className="publishInfos">{updateDate}</p>
                                                <a href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                    <h3>{blogpost.title}</h3></a>
                                            </div>
                                            <a className="readbloglink"
                                               href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                <div className='readbloglink-text'>Weiterlesen</div>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="element nopadding blogelement">
                                <div className="blogcolumn-element blogelements">
                                    <div className="blogcolumn">
                                        <a href={"/blog"}>
                                            <div className="blogline">
                                            </div>
                                        </a>
                                    </div>
                                    <div className="bloginfo">
                                        <a href={"/blog"}><h3>Weitere Blogbeiträge</h3></a>
                                    </div>
                                    <a className="readbloglink"
                                       href={"/blog"}>
                                        <div className='readbloglink-text'>Weiterlesen</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </Layout>
    )
}

export default IndexPage


export const pageQuery = graphql`
query HomePageQuery {
    allStrapiHomePages {
        edges {
            node {
                slogan
                cards_section
                content
                refrences_section
                blog_section
                chatbot_workshop
                team_section
                callToAction_section
                callToAction_button
                references {
                    url
                }
                photo {
                    publicURL
                }
            }
        }
    }
    allStrapiOfferPages {
        edges {
            node {
                title
                url
                description
                card_image {
                    publicURL
                }
                show_as_card
                order
            }
        }
    }
    allStrapiBlogs {
        edges {
            node {
                publishDate
                title
                summary
                urlName
                status
                category {
                    name
                    color
                    url
                }
                blog_photo {
                    publicURL
                }
            }
        }
    }
    allStrapiBlogCategories {
        edges {
            node {
                name
                blog_category_icon {
                    publicURL
                }
            }
        }
    }
    allStrapiReferences {
        edges {
            node {
                url
                title
                filmes {
      title
      url
    }
                companyImage {
                    publicURL
                }
            }
        }
    }
}
`
